export class Test {
    testId: number;
    examId: number;
    testName: string;
    displayOrder: number;
    schedule?: ExamScheduleList;
}

export class Exam {
    examId: number;
    examName: string;
    examType: string;
    displayOrder: number;
    scheduleStatus: string;
    marksStatus: string;
    classroomId: number;
    tests: Test[];
}

export class Marks {
    id: number;
    studentProfileId: number;
    studentName: string;
    rollNbr: string;
    examId: number;
    examName: string;
    testId: number;
    testName: string;
    subjectId: number;
    subjectName: string;
    skillId: number;
    marksScored: any;
    maxMarks: number;
    grade: any;
    gradePoint: any;
    isMapped: any;
    isAbsent: any;
    classroomId:number;
}

export class Remarks {
    admissionNumber: string;
    classroomId: number;
    examId: number;
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
    recordStatus: string;
    remarks: string;
    rollNbr: string;
    studentProfileId: number;
}

export class StudentMarks {
    marksList: Marks[];
    rollNbr: string;
    studentName: string;
    studentProfileId: number;
}

export class ExamScheduleList {
    examId: number;
    testId: number;
    examinationName: string;
    testName: string;
    schedule: ExamSchedule[]
}

export class ExamSchedule {
    id: number;
    subjectId: number;
    subjectName: string;
    date: string;
    startTime: string;
    endTime: string;
    duration: number;
}